<template>
  <div>
    <div class="detail-title">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>用户</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/userMessage' }"
        >消息通知</el-breadcrumb-item
        >
        <el-breadcrumb-item>发布消息</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="detail-info">
      <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-width="120px"
          size="mini"
      >
        <el-form-item label="姓名" prop="name">
          <el-input
              v-model="form.name"
              style="width: 240px"
              show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="年级" prop="grade">
          <el-select v-model="form.grade" placeholder="请选择年级" style="width: 240px">
            <el-option
                v-for="item in gradeOption"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类型" prop="type">
          <el-select v-model="form.type" placeholder="请选择类型" style="width: 240px">
            <el-option
                v-for="item in typeOption"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学生uid" prop="uid">
          <el-select
              style="width: 240px"
              v-model="form.uid"
              :loading="loading"
              :remote-method="searchStudent"
              clearable
              filterable
              placeholder="输入学生uid"
              remote
              reserve-keyword
              size="mini"
          >
            <el-option
                v-for="item in users"
                :key="item.id"
                :label="item.id"
                :value="item.id"
                style="height: 40px; line-height: 40px"
            >
              <div
                  style="
                          display: flex;
                          justify-content: start;
                          align-items: center;
                          height: 40px;
                          line-height: 40px;
                        "
              >
                <span style="margin-right: 10px"> {{ item.id }} </span>
                <el-avatar
                    :src="item.avatar_url"
                    size="small"
                ></el-avatar>
                <span style="margin-left: 10px">{{ item.name }}</span>
              </div>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="班主任" prop="teacher_uid">
          <el-select
              style="width: 240px"
              v-model="form.teacher_uid"
              :loading="loading"
              :remote-method="searchStudent"
              clearable
              filterable
              placeholder="输入班主任uid"
              remote
              reserve-keyword
              size="mini"
          >
            <el-option
                v-for="item in users"
                :key="item.id"
                :label="item.id"
                :value="item.id"
                style="height: 40px; line-height: 40px"
            >
              <div
                  style="
                          display: flex;
                          justify-content: start;
                          align-items: center;
                          height: 40px;
                          line-height: 40px;
                        "
              >
                <span style="margin-right: 10px"> {{ item.id }} </span>
                <el-avatar
                    :src="item.avatar_url"
                    size="small"
                ></el-avatar>
                <span style="margin-left: 10px">{{ item.name }}</span>
              </div>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="课程顾问" prop="counselor_uid">
          <el-select
              style="width: 240px"
              v-model="form.counselor_uid"
              :loading="loading"
              :remote-method="searchStudent"
              clearable
              filterable
              placeholder="输入课程顾问uid"
              remote
              reserve-keyword
              size="mini"
          >
            <el-option
                v-for="item in users"
                :key="item.id"
                :label="item.id"
                :value="item.id"
                style="height: 40px; line-height: 40px"
            >
              <div
                  style="
                          display: flex;
                          justify-content: start;
                          align-items: center;
                          height: 40px;
                          line-height: 40px;
                        "
              >
                <span style="margin-right: 10px"> {{ item.id }} </span>
                <el-avatar
                    :src="item.avatar_url"
                    size="small"
                ></el-avatar>
                <span style="margin-left: 10px">{{ item.name }}</span>
              </div>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="报名时间" prop="apply_at">
          <el-date-picker
              style="width: 240px"
              v-model="form.apply_at"
              type="date"
              format="yyyy-MM-dd"
              value-format="yyyy-MM-dd"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="报名内容" prop="apply_product" >
          <el-input
              v-model="form.apply_product"
              style="width: 340px"
              rows="4"
              type="textarea"
              maxlength="150"
              show-word-limit
          ></el-input>
        </el-form-item>

        <el-form-item label="续费跟进情况" >
          <el-input
              v-model="form.remark"
              style="width: 340px"
              rows="4"
              type="textarea"
              maxlength="250"
              show-word-limit
          ></el-input>
        </el-form-item>

        <el-form-item label="状态" >
          <div style="display: flex;align-items: center">
            <el-switch
                v-model="form.status"
                active-color="#13ce66"
                :active-value="1"
                :inactive-value="2"
                inactive-color="#ff4949">
            </el-switch>
            <span style="margin-left: 10px">
              {{form.status === 1 ? '试听跟进中' : '已报名'}}
            </span>
          </div>
        </el-form-item>



        <el-form-item>
          <el-button
              size="small"
              type="primary"
              :loading="loading"
              @click="onSubmit"
          >保存</el-button
          >
          <router-link to="/newStudent" class="router-link">
            <el-button style="margin-left: 10px" size="small">取消</el-button>
          </router-link>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "template-add",
  data() {
    return {
      loading: false,
      selectCurrentUser:{},
      users:[],
      gradeOption:[
        {value:'一年级',label:'一年级',},
        {value:'二年级',label:'二年级',},
        {value:'三年级',label:'三年级',},
        {value:'四年级',label:'四年级',},
        {value:'五年级',label:'五年级',},
        {value:'六年级',label:'六年级',},
        {value:'七年级',label:'七年级',},
        {value:'八年级',label:'八年级',},
        {value:'九年级',label:'九年级',},
      ],
      typeOption:[
        {value:1,label:'录播课',},
        {value:2,label:'直播课',},
        {value:3,label:'直播小班课',},
        {value:4,label:'直播大班课',},
      ],
      form: {
        name:'',
        grade:'',
        uid:'',
        apply_product:'',
        teacher_uid:'',
        counselor_uid:'',
        type:'',
        apply_at:'',
        remark:'',
        status:1
      },

      rules: {
        name: [{ required: true, message: "请输入年级", trigger: "blur" }],
        grade: [{ required: true, message: "请输入年级", trigger: "blur" }],
        type: [{ required: true, message: "请选择类型", trigger: "blur" }],
        uid: [{ required: true, message: "请选择类型", trigger: "blur" }],
        teacher_uid: [{ required: true, message: "请输入班主任uid", trigger: "blur" }],
        counselor_uid: [{ required: true, message: "请输入课程顾问uid", trigger: "blur" }],
        apply_at: [{ required: true, message: "请输入报名时间", trigger: "blur" }],
        apply_product: [{ required: true, message: "报名内容", trigger: "blur" }],
      },
    };
  },
  methods: {
    ...mapActions("user", ["publish"]),
    ...mapActions("user", ["getUserList","setNewStudent"]),
    searchStudent(v) {
      let searchData = {};
      searchData.uid = v;

      this.notData = false;
      this.getUserList(searchData).then((res) => {
        if (res.data.list.length === 0) {
          this.notData = true;
        }
        this.users = res.data.list;
      });
    },
    async onSubmit() {
      let _this = this;
      this.loading = true;
      console.log(this.form);

      if (!this.validateForm("form")) {
        this.$message.error("请填写必填选项！");
        return false;
      }
      const { res_info } = await this.setNewStudent(this.form);

      this.loading = false;
      if (res_info != "ok") return;
      this.$router.go(-1);
      this.$message.success("新增成功");
    },

    validateForm(formName) {
      let validate = false;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          validate = true;
          return true;
        } else {
          validate = false;
          return false;
        }
      });
      return validate;
    },
  },
};
</script>

<style scoped></style>
